import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Player = makeShortcode("Player");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Quantize audio so it sounds clean without warping`}</h1>
    <ol>
      <li parentName="ol">{`Select an audio clip you want to quantize`}</li>
      <li parentName="ol">{`Make sure the onsets align with the transients you want to be quantized`}</li>
      <li parentName="ol">{`use `}<strong parentName="li">{`Split at onsets`}</strong>{` on the whole clip. 3. Select all clips and quantize them.`}</li>
      <li parentName="ol">{`select everything and reverse it. 5. use "make legato" on everything.`}</li>
      <li parentName="ol">{`"Reverse" everything again so it's no longer reversed.`}</li>
      <li parentName="ol">{`use "auto-fade"`}</li>
      <li parentName="ol">{`hold shift while dragging the cross fades slightly behind so that it sounds smooth.`}</li>
      <li parentName="ol">{`profit!`}</li>
    </ol>
  <Player playsInline src="https://cdn.discordapp.com/attachments/649293987848519691/652216601420234778/2019-12-05_16-52-34.webm" mdxType="Player" />
    <h2>{`Need more?`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/tips-tricks"
        }}>{`more Bitwig Tips & Tricks`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/wiki/"
        }}>{`BitWiki Overview`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      